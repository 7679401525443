import {MediaTypeEnum} from '@graphql/graphql';

export const MediaTypeOptions = {
  [MediaTypeEnum.Conference]: 'Mozaiek Conference',
  [MediaTypeEnum.Service]: 'Diensten en preken',
  [MediaTypeEnum.Kids]: 'Ollie en Sophie',
  [MediaTypeEnum.Worship]: 'Mozaiek Worship',
  [MediaTypeEnum.Way]: 'WAY',
  [MediaTypeEnum.Podcast]: 'Podcast',
  [MediaTypeEnum.Other]: 'Algemeen',
};
